import React, { useState, useEffect } from 'react';
import profileImage from '../assets/img/anilWB.png';
import './Home.css';

function Home() {
  const titles = ["Backend Developer", "Security Engineer"];
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
        setIsAnimating(false);
      }, 800); // Half of the transition time
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="home section" id="home">
      <div className="home__container container grid">
        <div className="home__data">
          <span className="home__greeting">Hello, I'm</span>
          <h1 className="home__name">Anil Kumar</h1>
          <h3 className="home__education">
            <span className={`title ${isAnimating ? 'slide-out' : ''}`}>
              {titles[currentTitleIndex]}
            </span>
          </h3>
          <div className="home__buttons">
            <a download="" href="https://drive.google.com/file/d/1rAJZgW2gr1msuzvLbhHDHvHFbectG0TG/view?usp=sharing" className="button button__ghost">Download CV</a>
            <a href="#about" className="button">About me</a>
          </div>
        </div>
        <div className="home__handle">
          <img src={profileImage} alt="profile" className="home__img" />
        </div>
        <div className="home__social">
          <a href="https://www.linkedin.com/in/anilk2001/" className="home__social-link" target="_blank" rel="noreferrer"><i className="bx bxl-linkedin-square"></i></a>
          <a href="https://github.com/A-K-2001" className="home__social-link" target="_blank" rel="noreferrer"><i className="bx bxl-github"></i></a>
        </div>
        <a href="#about" className="home__scroll">
          <i className="bx bx-mouse home__scroll-icon"></i>
          <span className="home__scroll-name">Scroll Down</span>
        </a>
      </div>
    </section>
  );
}

export default Home;