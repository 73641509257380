import React from 'react';

function Skills() {
  return (
    <section className="skills section" id="skills">
      <span className="section__subtitle">My abilities</span>
      <h2 className="section__title">My Skills</h2>
      <div className="skills__container container grid">
        <div className="skills__content">
          <h3 className="skills__title">Languages</h3>
          <div className="skills__box">
            <div className="skills__group">
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Go,</h3>
                  {/* <span className="skills__level">Basic</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">JavaScript,</h3>
                  {/* <span className="skills__level">Advanced</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">C/C++</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">SQL</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
            </div>
           
          </div>
        </div>





        <div className="skills__content">
          <h3 className="skills__title">Web Technologies</h3>
          <div className="skills__box">
            <div className="skills__group">
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Node.js</h3>
                 
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Express.js</h3>
                  {/* <span className="skills__level">Basic</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">React.js</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Golang</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Docker</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
            </div>
            
            <div className="skills__group">
            <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">PostgreSQL</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">MongoDB</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Firebase</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Jest</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Dokku</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="skills__content">
          <h3 className="skills__title">Cybersecurity</h3>
          <div className="skills__box">
            <div className="skills__group">
            <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Web Application Security</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Cryptography</h3>
                 
                </div>
              </div>
              {/* <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Express.js</h3>
                  <span className="skills__level">Basic</span>
                </div>
              </div> */}
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Steganography</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Malware Analysis</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Vulnerability Assessment</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
             
            </div>
            
            <div className="skills__group">
            <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Defence-in-Depth</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
            <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Wireshark</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Nmap,</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">GDB</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">OpenSSL</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Network security</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>

              
            </div>
          </div>
        </div>



        <div className="skills__content">
          <h3 className="skills__title">Tools</h3>
          <div className="skills__box">
            <div className="skills__group">
            <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Git</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">MySQL,</h3>
                 
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Postman</h3>
                  {/* <span className="skills__level">Basic</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Linux</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Malware Analysis</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Vulnerability Assessment</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
             
            </div>
            
            <div className="skills__group">
            <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">AWS</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
            <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">VMware</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Powershell,</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">Bash</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
              <div className="skills__data">
                <i className="bx bxs-badge-check"></i>
                <div>
                  <h3 className="skills__name">GitHub</h3>
                  {/* <span className="skills__level">Intermediate</span> */}
                </div>
              </div>
            

              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;