import React from 'react';
import farmako from '../assets/img/farmako.png'
import maq from '../assets/img/maq.png'
import scopex from '../assets/img/scopex.png'

function Experience() {
  return (
    <section className="services section">
      <span className="section__subtitle">My Experience</span>
      <h2 className="section__title">Where I Work/Worked...</h2>
      <div className="services__container container grid">
        <div className="services__card">
          <img src={farmako} alt='farmako' className="work__img"/>
          <h3 className="services__title">
            Farmako (YC S20)
          </h3>
          <span className="services__button">
            Backend Developer
          </span>
          <p>Feb 2024 - present</p>
        </div>
        <div className="services__card">
          <img src={maq} alt='farmako' className="work__img"/>
          <h3 className="services__title">
          MAQ Software
          </h3>
          <span className="services__button">
          Associate Software Engineer
          </span>
          <p>Dec 2023 - Feb 2024</p>
        </div>
        <div className="services__card">
          <img src={scopex} alt='farmako' className="work__img"/>
          <h3 className="services__title">
          ScopeX Fintech
          </h3>
          <span className="services__button">
          Full Stack Developer intern 
          </span>
          <p>Mar 2023 - Dec 2023</p>
        </div>
        
      </div>
    </section>
  );
}

export default Experience;