import React from 'react';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Skills from './components/Skills';
import Experience from './components/Experience';
import Work from './components/Work';
// import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import useTheme from './useTheme';
import './App.css';
import 'boxicons/css/boxicons.min.css';
import 'swiper/css';
import 'swiper/css/pagination';

function App() {

  const { theme, icon, toggleTheme } = useTheme();

  React.useEffect(() => {
    document.body.className = theme === 'dark' ? '' : 'light-theme';
  }, [theme]);


  return (
    <div className={`App ${theme === 'dark' ? 'vsc-initialized' : 'vsc-initialized light-theme'}`}>
      <Header theme={theme} icon={icon} toggleTheme={toggleTheme} />
      <main className="main">
        <Home />
        <About />
        <Experience />
        <Skills />
        <Work />
        {/* <Testimonials /> */}
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;