import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container container">
        <h1 className="footer__title">Anil Kumar</h1>
        <ul className="footer__list">
          <li><a href="#about" className="footer__link">About</a></li>
          <li><a href="#work" className="footer__link">Projects</a></li>
          <li><a href="#skills" className="footer__link">Skills</a></li>
        </ul>

        <ul className="footer__social">
          <a href="https://www.linkedin.com/in/anilk2001/" className="footer__social-link" target="_blank" rel="noreferrer">
            <i className='bx bxl-linkedin-square'></i>
          </a>
          <a href="https://github.com/A-K-2001" className="footer__social-link" target="_blank" rel="noreferrer">
            <i className='bx bxl-github'></i>
          </a>
          {/* <a href="https://twitter.com/" className="footer__social-link" target="_blank" rel="noreferrer">
            <i className='bx bxl-twitter'></i>
          </a> */}
        </ul>
        <span style={{marginTop:"3%"}} className="footer__copy">&#169; Anilkumar. All rigths reserved</span>
      </div>
    </footer>
  );
}

export default Footer;