import React from 'react';

function Contact() {
  return (
    <section className="contact section" id="contact">
      <span className="section__subtitle">Get in touch</span>
      <h2 className="section__title">Contact Me</h2>
      <div className="contact__container container grid">
        <div className="contact__content">
          <h3 className="contact__title">Talk to me</h3>
          <div className="contact__info">
            <div className="contact__card">
              <i className='bx bx-mail-send contact__card-icon'></i>
              <h3 className="contact__card-title">Email</h3>
              <span className="contact__card-data">kumaranilstps017@gmail.com</span>
              <a href="mailto:examplemail@correo.com" target="_blank" rel="noreferrer" className="contact__button">
                Write me <i className='bx bx-right-arrow-alt contact__button-icon'></i>
              </a>
            </div>
            <div className="contact__card">
              <i className='bx bxl-whatsapp contact__card-icon'></i>
              <h3 className="contact__card-title">Whatsapp</h3>
              <span className="contact__card-data">+91 7232076366</span>
              <a href="https://api.whatsapp.com/send?phone=+917232076366&text=Hello, more information!" target="_blank" rel="noreferrer" className="contact__button">
                Write me <i className='bx bx-right-arrow-alt contact__button-icon'></i>
              </a>
            </div>
            <div className="contact__card">
              <i className='bx bxl-linkedin contact__card-icon'></i>
              <h3 className="contact__card-title">Linkedin</h3>
              <span className="contact__card-data">Anil kumar</span>
              <a href="https://www.linkedin.com/in/anilk2001/" target="_blank" rel="noreferrer" className="contact__button">
                Connect Me <i className='bx bx-right-arrow-alt contact__button-icon'></i>
              </a>
            </div>
          </div>
        </div>
        <div className="contact__content">
          <h3 className="contact__title">Write me your Message</h3>
          <form className="contact__form">
            <div className="contact__form-div">
              <label className="contact__form-tag">Names</label>
              <input type="text" className="contact__form-input" placeholder="Insert your name" />
            </div>
            <div className="contact__form-div">
              <label className="contact__form-tag">Mail</label>
              <input type="email" className="contact__form-input" placeholder="Insert your email" />
            </div>
            <div className="contact__form-div contact__form-area">
              <label className="contact__form-tag">Message</label>
              <textarea className="contact__form-input" placeholder="Write your Message"></textarea>
            </div>
            <button className="button">Send Message</button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;