// src/useTheme.js
import { useState, useEffect } from 'react';
const useTheme = () => {
  const [theme, setTheme] = useState(() => localStorage.getItem('selected-theme') || 'dark');
  const [icon, setIcon] = useState(() => localStorage.getItem('selected-icon') || 'bx-moon');

  useEffect(() => {
    document.body.classList.toggle('light-theme', theme === 'dark');
    localStorage.setItem('selected-theme', theme);
    localStorage.setItem('selected-icon', icon);
  }, [theme, icon]);

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
    setIcon(prevIcon => prevIcon === 'bx-sun' ? 'bx-moon' : 'bx-sun');
  };

  return { theme, icon, toggleTheme };
};

export default useTheme;


