import React from 'react';
import work1 from '../assets/img/letsgrow.png';
import work2 from '../assets/img/opa.png';
import work3 from '../assets/img/bizchrome.png';
// import work4 from '../assets/img/work4.jpg';
// import work5 from '../assets/img/work5.jpg';

function Work() {
  return (
    <section className="work section" id="work">
      <span className="section__subtitle">My Portfolio</span>
      <h2 className="section__title">Recent Works</h2>
      {/* <div className="work__filters">
        <span className="work__item active-work" data-filter="all">All</span>
        <span className="work__item" data-filter=".web">Web</span>
        <span className="work__item" data-filter=".movil">Movil</span>
        <span className="work__item" data-filter=".design">Design</span>
      </div> */}
      <div className="work__container container grid">
        <div className="work__card mix web">
          <img src={work1} alt="work1" className="work__img" />
          <h3 className="work__title">Let's Grow</h3>
          <a href="https://letsgroww.vercel.app/" className="work__button">
            Demo <i className="bx bx-right-arrow-alt work-icon"></i>
          </a>
        </div>

        <div className="work__card mix movil">
          <img src={work2} alt="work2" className="work__img" />
          <h3 className="work__title">Opa rental</h3>
          <a href="https://oparental.com/" className="work__button">
            Demo <i className="bx bx-right-arrow-alt work-icon"></i>
          </a>
        </div>

        <div className="work__card mix design">
          <img src={work3} alt="work3" className="work__img" />
          <h3 className="work__title">Bizchrome</h3>
          <a href="https://bizchrome.ai/" className="work__button">
            Demo <i className="bx bx-right-arrow-alt work-icon"></i>
          </a>
        </div>

        {/* <div className="work__card mix web"> */}
          {/* <img src={work4} alt="work4" className="work__img" />
          <h3 className="work__title">Web design</h3>
          <a href="#" className="work__button">
            Demo <i className="bx bx-right-arrow-alt work-icon"></i>
          </a>
        </div>

        <div className="work__card mix movil">
          <img src={work5} alt="work5" className="work__img" />
          <h3 className="work__title">App movil</h3>
          <a href="#" className="work__button">
            Demo <i className="bx bx-right-arrow-alt work-icon"></i>
          </a>
        </div> */}
      </div>
    </section>
  );
}

export default Work;