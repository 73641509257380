import React from 'react';
import aboutImage from '../assets/img/anilWD.jpg';

function About() {
  return (
    <section className="about section" id="about">
      <span className="section__subtitle">My Intro</span>
      <h2 className="section__title">About Me</h2>
      <div className="about__container container grid">
        <img src={aboutImage} alt="about" className="about__img" />
        <div className="about__data">
          {/* <div className="about__info">
            <div className="about__box">
              <i className="bx bx-award about-icon"></i>
              <h3 className="about__title">Experience</h3>
              <span className="about__subtitle">1 Years Working</span>
            </div>
            <div className="about__box">
              <i className="bx bx-briefcase-alt about-icon"></i>
              <h3 className="about__title">Completed</h3>
              <span className="about__subtitle">48 + Projects</span>
            </div>
            <div className="about__box">
              <i className="bx bx-support about-icon"></i>
              <h3 className="about__title">Support</h3>
              <span className="about__subtitle">Online 24/7</span>
            </div>
          </div> */}
          <p className="about__description">
            I'm Anil kumar, a Software Developer transitioning into Cybersecurity
            <br></br>
            <br></br>

              I'm well versed in the technical domains of Software Development and Cybersecurity.
              <br></br>
              <br></br>
              I'm a Computer Science and Engineering B.Tech graduate from Indian Institute of Information Technology and Management, Gwalior.
              <br></br>
              <br></br>
              Feel free to reach out to me in case of any job opportunites.
            </p>
            <a href="#contact" className="button">Contact Me</a>
        </div>
      </div>
    </section>
  );
}

export default About;