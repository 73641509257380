import React, { useState, useEffect } from 'react';

function Header({ theme, icon, toggleTheme }) {
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'about', 'skills', 'work', 'contact'];
      // const middleOfScreen = window.innerHeight / 2;

      let mostVisibleSection = null;
      let maxVisibility = 0;

      sections.forEach(sectionId => {
        const section = document.getElementById(sectionId);
        if (section) {
          const rect = section.getBoundingClientRect();
          const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
          const visibility = visibleHeight / section.offsetHeight;

          if (visibility > maxVisibility) {
            maxVisibility = visibility;
            mostVisibleSection = sectionId;
          }
        }
      });

      if (mostVisibleSection) {
        setActiveSection(mostVisibleSection);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial active section
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className="header" id="header">
      <nav className="nav container">
        <a href="#home" className="nav__logo">Anil Kumar</a>
        <div className="nav__menu">
          <ul className="nav__list">
            {[
              { id: 'home', icon: 'bx-home' },
              { id: 'about', icon: 'bx-user' },
              { id: 'skills', icon: 'bx-book' },
              { id: 'work', icon: 'bx-briefcase-alt-2' },
              { id: 'contact', icon: 'bx-message-square-detail' }
            ].map(({ id, icon }) => (
              <li key={id} className="nav__item">
                
                 <a href={`#${id}`}
                  className={`nav__link ${activeSection === id ? 'active-link' : ''}`}
                >
                  <i className={`bx ${icon}`}></i>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <button
          style={{ background: "transparent", color: "#77bef8" }}
          className={`bx ${icon} change-theme`}
          id="theme-button"
          onClick={toggleTheme}
        ></button>
      </nav>
    </header>
  );
}

export default Header;